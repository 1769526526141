import { atom } from "jotai";
import { deleteAuthCookies, getCookie } from "../Utils/CookieUtil";
import { userAtom } from "../Atoms/PublicAtom";
import {
  errorModalAtom,
  fromLandingPageInterviewDataAtom,
  initializeStateAtom,
} from "../Atoms/RootAtom";
import { agreementAtom } from "../Atoms/SignupAtom";
import { handleApiResponse } from "../Utils/APIUtil";
import { getUserInfoWhenStart } from "../Queries/AuthQueries";

export const checkCookieAndAutoLoginAtom = atom(
  (get) => get(initializeStateAtom),
  async (get, set) => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    if (refreshCookie && accessCookie) {
      const errorFunction = () => {
        deleteAuthCookies();
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "오류가 발생하셨습니다.\n서비스를 사용하시고 싶으시면 다시 로그인 해주세요.",
          title: "오류 발생",
        });
      };

      const successFunction = (results: any) => {
        set(userAtom, {
          idx: results.id,
          id: results.user.username,
          image: results.profile_image ?? "",
          membership: results.membership,
          nickname: results.nickname,
          membership_end_date: new Date(results.end_date),
        });

        if (new Date() > new Date(results.end_date)) {
          set(errorModalAtom, {
            state: true,
            event: () =>
              (window.location.href =
                process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
            eventText: "플랜 갱신",
            redirectUrl: "",
            text: "플랜을 갱신해주세요.\n만료되었어요.",
            title: "플랜 만료",
          });
        } else {
          if (results.user.username === "ipsispace_test@dokgabi.ai") {
            window.location.href = "https://ipsispace.dokgabi.ai";
          } else {
            window.location.href =
              process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS;
          }
        }
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () => getUserInfoWhenStart(accessCookie),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () => getUserInfoWhenStart(newAccessToken),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }

      set(initializeStateAtom, true);
    }
  }
);

export const initializeLandingAppAtom = atom(null, (get, set) => {
  window.addEventListener("message", (event) => {
    const allowedOrigins = (
      process.env.REACT_APP_DOKGABI_HOME_ADDRESS || ""
    ).split(",");

    if (!allowedOrigins.includes(event.origin)) {
      return;
    }

    const quesAns = event.data?.quesAns;

    if (quesAns !== undefined) {
      set(fromLandingPageInterviewDataAtom, quesAns);
    }
  });
});

export const whenClosedInitializedAtom = atom(null, (get, set) => {
  // set(agreeMentModalAtom, false);
  set(agreementAtom, [false, false, false, false]);
});

// export const SignUpHereTooAtom = atom(null, async (get, set, { navigate }) => {
//   const agree = get(agreementAtom);
//   const { username, password } = get(loginValueAtom);

//   if (agree[0] && agree[1] && agree[2]) {
//     const data = {
//       email: username,
//       password: password,
//       site: "dokgabi",
//       membership: "free",
//       marketing_agreement: agree[3],
//     };

//     const response = await signupHereToo(data);

//     if (response) {
//       set(agreementAtom, [false, false, false, false]);
//       set(agreeMentModalAtom, false);

//       set(successModalAtom, {
//         state: true,
//         redirectUrl: "",
//         text: "연동이 되었습니다.\n해당 이메일과 비밀번호로 다시 로그인해주세요.\nFREE 플랜은 기능 제한이 많이 되니\n플랜 업그레이드를 통해 독갑이 기능들을 즐겨보세요.",
//         title: "연동 성공",
//         event: null,
//         eventText: "",
//       });
//     } else {
//       set(errorModalAtom, {
//         state: true,
//         redirectUrl: "",
//         text: "네트워크 오류가 발생했습니다.\n잠시 뒤에 시도해주세요.",
//         title: "네트워크 오류",
//         event: null,
//         eventText: "",
//       });
//     }
//   } else if (!agree[0] || !agree[1] || !agree[2]) {
//     set(errorModalAtom, {
//       state: true,
//       redirectUrl: "",
//       text: "필수로 표시된 약관은 동의하셔야\n회원가입이 완료 됩니다.",
//       title: "약관동의 오류",
//       event: null,
//       eventText: "",
//     });
//   } else {
//     set(errorModalAtom, {
//       state: true,
//       redirectUrl: "",
//       text: "네트워크 오류가 발생했습니다.\n잠시 뒤에 시도해주세요.",
//       title: "네트워크 오류",
//       event: null,
//       eventText: "",
//     });
//   }
// });
